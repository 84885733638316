import React from 'react';
import startHerePDF from './assets/START HERE.pdf';
import investing101PDF from './assets/Investing 101 A Beginners Guide.pdf';
import portfolioGuidePDF from './assets/Step By Step Guide to Create The Perfect Portfolio.pdf';
import stockMarketAcademyPDF from './assets/Stock Market Academy.pdf';
import dividendExpertisePDF from './assets/Dividend Expertise.pdf';
import wealthChecklistPDF from './assets/The Wealth Checklist.pdf';

function DownloadAllGuides() {
    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center py-12 px-4 sm:px-6 lg:px-8">
            {/* Guides Download Section */}
            <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-5xl w-full">
                <h2 className="text-3xl font-bold text-gray-900 mb-6">Thank you for your payment. You can download your guides here.</h2>
                <p className="text-2xl font-bold text-red-600 mb-6">Download all your guides and join the server now or save the page as a favorite, as you won't have another link to access it.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <a
                        href={startHerePDF}
                        download="START HERE.pdf"
                        className="bg-gray-200 p-6 rounded-lg shadow-lg hover:bg-gray-300 transition duration-300"
                    >
                        <h3 className="text-xl font-semibold mb-2">START HERE: Getting Started Guide</h3>
                        <p className="text-gray-700">The first step in your journey to mastering investing.</p>
                    </a>
                    <a
                        href={investing101PDF}
                        download="Investing 101 A Beginners Guide.pdf"
                        className="bg-gray-200 p-6 rounded-lg shadow-lg hover:bg-gray-300 transition duration-300"
                    >
                        <h3 className="text-xl font-semibold mb-2">Investing 101: A Beginner’s Guide</h3>
                        <p className="text-gray-700">Your essential guide to getting started in the stock market.</p>
                    </a>
                    <a
                        href={portfolioGuidePDF}
                        download="Step By Step Guide to Create The Perfect Portfolio.pdf"
                        className="bg-gray-200 p-6 rounded-lg shadow-lg hover:bg-gray-300 transition duration-300"
                    >
                        <h3 className="text-xl font-semibold mb-2">Step-by-Step Guide to Create The Perfect Portfolio</h3>
                        <p className="text-gray-700">Learn how to build a well-balanced investment portfolio.</p>
                    </a>
                    <a
                        href={stockMarketAcademyPDF}
                        download="Stock Market Academy.pdf"
                        className="bg-gray-200 p-6 rounded-lg shadow-lg hover:bg-gray-300 transition duration-300"
                    >
                        <h3 className="text-xl font-semibold mb-2">Stock Market Academy</h3>
                        <p className="text-gray-700">Master the stock market with this comprehensive guide.</p>
                    </a>
                    <a
                        href={dividendExpertisePDF}
                        download="Dividend Expertise.pdf"
                        className="bg-gray-200 p-6 rounded-lg shadow-lg hover:bg-gray-300 transition duration-300"
                    >
                        <h3 className="text-xl font-semibold mb-2">Dividend Expertise</h3>
                        <p className="text-gray-700">Learn how to earn passive income through dividend investing.</p>
                    </a>
                    <a
                        href={wealthChecklistPDF}
                        download="The Wealth Checklist.pdf"
                        className="bg-gray-200 p-6 rounded-lg shadow-lg hover:bg-gray-300 transition duration-300"
                    >
                        <h3 className="text-xl font-semibold mb-2">The Wealth Checklist</h3>
                        <p className="text-gray-700">Follow this checklist to stay on track toward financial freedom.</p>
                    </a>
                </div>
            </div>

            {/* Discord Link and Premium Member Info */}
            <div className="bg-gray-900 text-white p-6 rounded-lg shadow-lg text-center w-full max-w-4xl mt-12">
                <p className="text-xl mb-4">
                    Join our Discord Community as a Premium Member: <a href="https://discord.gg/TvPmWsZSTF" className="underline text-blue-400 hover:text-blue-300">https://discord.gg/TvPmWsZSTF</a>
                </p>
                <p className="text-lg">
                    DM me on Instagram, Discord, or email me with your Discord username to become a premium member.
                </p>
            </div>
        </div>
    );
}

export default DownloadAllGuides;
