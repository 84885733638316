import React, { useState, useEffect } from 'react';
import guidePDF from './assets/investing101.pdf'; 

function DownloadGuide() {
    const initialTimeLimit = 2 * 24 * 60 * 60; // 2 days in seconds
    const [timeLeft, setTimeLeft] = useState(initialTimeLimit);
    const [offerExpired, setOfferExpired] = useState(false); // Track if offer has expired

    useEffect(() => {
        const savedTime = localStorage.getItem('firstVisitTime');
        const now = new Date().getTime();

        if (savedTime) {
            const timePassed = Math.floor((now - savedTime) / 1000); // Convert ms to seconds
            const remainingTime = initialTimeLimit - timePassed;

            setTimeLeft(remainingTime > 0 ? remainingTime : 0);
        } else {
            localStorage.setItem('firstVisitTime', now);
        }
    }, [initialTimeLimit]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    setOfferExpired(true); // Mark the offer as expired
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatTime = (seconds) => {
        const days = Math.floor(seconds / (24 * 60 * 60));
        const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
        const minutes = Math.floor((seconds % (60 * 60)) / 60);
        const secs = seconds % 60;

        return `${days} days ${hours} hours ${minutes} minutes ${secs} seconds`;
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center py-12 px-4 sm:px-6 lg:px-8">
            {/* Special Offer Section */}
            {!offerExpired && (
                <div className="bg-white p-8 rounded-lg shadow-lg text-center mb-8 max-w-3xl w-full border-2 border-gray-300">
                    <div className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4">
                        🎉 Special Offer: Save 75% on Investment Mastery!
                    </div>
                    <p className="text-lg sm:text-xl text-gray-700 mb-6">
                        Get Investment Mastery for only <span className="font-bold">$49</span> (instead of <span className="line-through">$197</span>).
                        This exclusive offer ends in:
                    </p>
                    <div className="text-4xl font-extrabold text-red-600 mb-6">
                        {formatTime(timeLeft)}
                    </div>
                    <a
                        href="https://buy.stripe.com/28oaHg4eK0oc9WMaES"
                        className="bg-green-500 text-white py-3 px-6 rounded-lg text-lg sm:text-xl font-bold hover:bg-green-600 transition duration-300"
                    >
                        Get Investment Mastery Now
                    </a>
                </div>
            )}

            {offerExpired && (
                <div className="bg-red-100 p-8 rounded-lg shadow-lg text-center mb-8 max-w-3xl w-full border-2 border-red-300">
                    <div className="text-2xl sm:text-3xl font-bold text-red-600 mb-4">
                        ⏳ The Special Offer Has Expired!
                    </div>
                    <p className="text-lg sm:text-xl text-gray-700">
                        Sorry, but the 75% discount on Investment Mastery is no longer available. Stay tuned for future offers!
                    </p>
                </div>
            )}

            {/* Download and Discord Buttons */}
            <div className="bg-white p-10 rounded-lg shadow-xl text-center max-w-3xl w-full border-t-8 border-gray-200">
                <div className="text-2xl sm:text-3xl font-extrabold text-gray-900 mb-6">
                    Your Free Guide is Ready!
                </div>
                <p className="text-lg leading-7 text-gray-700 mb-8">
                    Download "Investing 101: A Beginner's Guide" below and join our investor community to stay up-to-date on the latest strategies.
                </p>

                <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-8">
                    <a
                        href={guidePDF}
                        download="Investing 101 A Beginners Guide.pdf"
                        className="bg-gray-800 text-white py-3 px-6 rounded-lg text-lg sm:text-xl font-bold hover:bg-gray-900 transition duration-300 shadow-lg"
                    >
                        Download Guide
                    </a>
                    <a
                        href="https://discord.gg/TvPmWsZSTF"
                        className="bg-purple-500 text-white py-3 px-6 rounded-lg text-lg sm:text-xl font-bold hover:bg-purple-600 transition duration-300 shadow-lg"
                    >
                        Join Our Discord Community
                    </a>
                </div>
            </div>
        </div>
    );
}

export default DownloadGuide;
