import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import productImage from './assets/investingproductpage.png';
import offerImage from './assets/investmentmastery.png';
import axios from 'axios';

// Function to get CSRF token
function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function Product1() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setShowPopup(true);
    };

    const handleContinueFreeGuide = async () => {
        try {
            const csrfToken = getCookie('csrftoken');

            // Send the email with the free guide
            await axios.post('/api/send_product_email/', { email }, {
                headers: {
                    'X-CSRFToken': csrfToken
                }
            });

            // Register email for marketing
            const response = await axios.post('/api/subscribe/', { email }, {
                headers: {
                    'X-CSRFToken': csrfToken
                }
            });

            // Redirect to download page
            if (response.status === 201 || response.status === 200) {
                window.location.href = '/download-guide';  // Ensure this path is correct
            }
        } catch (err) {
            if (err.response && err.response.status === 400 && err.response.data.email && err.response.data.email[0] === "This email is already subscribed.") {
                window.location.href = '/download-guide';  // Ensure this path is correct
            } else {
                setError('Something went wrong. Please try again.');
            }
        }
    };

    const handlePurchaseOffer = () => {
        window.location.href = 'https://buy.stripe.com/9AQ6r0h1wb2Qb0QaER';
    };

    return (
        <div className="min-h-screen bg-white flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold text-gray-900 mb-6 text-left">
                Investing 101: A Beginner’s Guide
            </h1>

            <div className="w-full max-w-md lg:max-w-lg mb-8 text-center">
                <img
                    src={productImage}
                    alt="Investing 101"
                    className="object-cover rounded-lg shadow-lg"
                />
            </div>

            <div className="mb-8 text-center" id="email-form">
                <form onSubmit={handleSubmit} className="flex flex-col items-center bg-yellow-100 p-8 rounded-lg shadow-lg border-4 border-yellow-400">
                    <p className="mb-4 text-lg font-bold text-gray-800 text-left">
                        Get your FREE copy of this bestseller by entering your email:
                    </p>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="Enter your email"
                        className="border border-gray-300 rounded-lg py-3 px-4 w-72 mb-4 focus:ring-2 focus:ring-yellow-500 focus:outline-none"
                    />
                    <button
                        type="submit"
                        className="mb-4 bg-gradient-to-r from-green-400 to-blue-500 text-white py-3 px-8 rounded-full text-lg font-extrabold shadow-lg hover:from-blue-500 hover:to-green-400 transform hover:scale-105 transition duration-300 ease-in-out"
                    >
                        Get Your Free Guide
                    </button>
                    <p className="text-gray-700"><strong>You'll receive everything INSTANTLY by email!</strong></p>
                    {error && <p className="text-red-500 mt-2">{error}</p>}
                </form>
            </div>

            <div className="max-w-2xl text-left">
                <p className="text-lg leading-7 mb-4">
                    <strong>Investing 101: A Beginner’s Guide</strong> is the ultimate guide for anyone looking to get started with investing. 
                    Whether you’re completely new to the stock market or already have some experience, this guide will help you 
                    grow your wealth quickly and effectively.
                </p>

                <h2 className="text-3xl font-bold mt-8 mb-4">What You'll Learn</h2>
                <p className="text-lg leading-7 mb-2">✅ <strong>Stock Market Basics:</strong> Understand how the stock market works.</p>
                <p className="text-lg leading-7 mb-2">✅ <strong>Investment Psychology:</strong> Manage your emotions for smarter decisions.</p>
                <p className="text-lg leading-7 mb-2">✅ <strong>Key Strategies:</strong> Simple strategies for stock market success.</p>
                <p className="text-lg leading-7 mb-2">✅ <strong>Goal Setting:</strong> Set and achieve realistic financial goals.</p>

                <h2 className="text-3xl font-bold mt-8 mb-4">Why You Need This Guide</h2>
                <p className="text-lg leading-7 mb-4">
                    "Investing 101: Getting Started" makes investing easy and accessible to anyone. Whether you have no knowledge, 
                    no money, or are already an investor, this guide will help you grow your wealth very quickly.
                </p>

                <h2 className="text-3xl font-bold mt-8 mb-4">Start Today</h2>
                <p className="text-lg leading-7 mb-4">
                    Download "Investing 101: Getting Started" now and take control of your financial future. It's totally FREE! What do you have to lose?
                </p>
            </div>

            {showPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-lg w-full sm:max-w-sm md:max-w-md lg:max-w-lg lg:p-10">
                        <div className="flex flex-col lg:flex-row items-center lg:items-start">
                            <div className="lg:mr-4 mb-4 lg:mb-0 text-center">
                                <img
                                    src={offerImage}
                                    alt="Investment Mastery Offer"
                                    className="w-28 h-28 lg:w-36 lg:h-36 object-cover rounded-lg shadow-lg"
                                />
                            </div>
                            <div className="text-left flex-1">
                                <h2 className="text-xl font-bold mb-2">Buy together and save</h2>
                                <p className="text-lg mb-2">
                                    <span className="text-green-500 font-bold">$49</span>{' '}
                                    <span className="line-through text-gray-500">$197</span>{' '}
                                    <span className="bg-blue-100 text-blue-600 px-2 py-1 rounded-full text-sm">-75% OFF</span>
                                </p>
                                <p className="text-gray-600 underline">Investment Mastery</p>
                                <p className="text-gray-600 mb-4">
                                    Learn how to turn $1,000 into $100,000 in 3 easy steps with stock market investing.
                                </p>
                                <button
                                    onClick={handlePurchaseOffer}
                                    className="bg-green-500 text-white py-2 px-4 rounded-lg text-lg font-bold hover:bg-green-600 transition duration-300 mb-4 mx-auto block lg:mx-0">
                                    Add and Save $150
                                </button>
                            </div>
                        </div>
                        <button
                            onClick={handleContinueFreeGuide}
                            className="text-gray-700 underline mt-4"
                        >
                            No thanks, just give me the free guide.
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Product1;
